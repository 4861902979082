"use client"

import posthog, { type PostHog } from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import React from "react"

export function CSPostHogProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [postHogClient, setPostHogClient] = React.useState<PostHog | undefined>()

  React.useEffect(() => {
    if (postHogClient) return
    if (window.location.host.includes("127.0.0.1") || window.location.host.includes("localhost")) return // skip in dev

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
      api_host: "/ingest",
      ui_host: "https://us.i.posthog.com",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug()
      },
    })
    setPostHogClient(posthog)
  }, [])

  return <PostHogProvider client={postHogClient || posthog}>{children}</PostHogProvider>
}
