"use client"
import { AppProgressBar as ProgressBar } from "next-nprogress-bar"

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ProgressBar height="4px" options={{ showSpinner: false }} shallowRouting />
      {children}
    </>
  )
}
