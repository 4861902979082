"use client"

import { useEffect } from "react"
import { useToast } from "@/components/ui/use-toast"

export default function ToastHandler() {
  const { toast } = useToast()

  useEffect(() => {
    const toastMessage = document.cookie
      .split("; ")
      .find((row) => row.startsWith("toast-message="))
      ?.split("=")[1]

    if (toastMessage) {
      // Clear the cookie
      document.cookie = "toast-message=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT"

      // Show the toast that remains until dismissed
      toast({
        title: decodeURIComponent(toastMessage),
        duration: Number.POSITIVE_INFINITY, // Makes the toast stay until manually dismissed
      })
    }
  }, [toast])

  return null
}
